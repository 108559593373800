import { createApp } from 'vue'
import WhoAmI from './Who-Am-I.vue'
import './registerServiceWorker'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@tensorflow/tfjs';
import '@mediapipe/drawing_utils' ;
import '@mediapipe/camera_utils';
import '@mediapipe/face_detection';

createApp(WhoAmI).mount('#app')
