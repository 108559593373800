<template>
  <footer class="footer mt-auto d-print-none">
    <div class="container-fluid py-3 px-4 d-flex flex-column align-items-center bg-dark">
      <div class="container">
        <div class="w-100 d-flex flex-row justify-content-around">
          <div class="cursor-pointer" :class="[mode === 'top' ? 'footer-active' : '']"
               @click="$emit('changeMode', 'top')">
            <i class="text-white bi bi-person-bounding-box h1"></i>
          </div>
          <div class="cursor-pointer" :class="[mode === 'selection' ? 'footer-active' : '']"
               @click="$emit('changeMode', 'selection')">
            <i class="text-white bi bi-person-fill-check h1"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid py-2 px-4 d-flex flex-column align-items-center bg-light">
      <div class="container">
        <p class="mb-0 text-center text-secondary">
          <small>
            ©&nbsp;2023&nbsp;by Patrick Willnow & Max Sternitzke<br>
            <span style="font-size: 8px">v1.0.0</span>
          </small>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "WhoAmI-Footer",
  props: ["mode"]
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.footer-active {
  border-bottom: 3px solid white;
}
</style>