<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="../../public/img/logo-transparent.png" alt="Logo" width="auto" height="30px" class="d-inline-block align-text-top me-3">
        Who Am I?</a>
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <a class="nav-link text-white" href="#" data-bs-toggle="modal" data-bs-target="#whoamiSettingsModal"> <i class="bi bi-sliders"></i></a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "WhoAmI-Navbar"
}
</script>

<style scoped>

</style>