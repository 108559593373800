<template>
  <div class="d-flex flex-column flex-grow-1 ">
    <WhoAmI-Navbar></WhoAmI-Navbar>
    <WhoAmISettingsModal @update-settings="updateSettings"></WhoAmISettingsModal>
    <WhoAmIContent :mode="mode" :model-selected="tfModelInfo" :number-of-names="numberOfNames" ref="content" @fetched-new-camera="updateCamera"></WhoAmIContent>
  </div>
  <WhoAmI-Footer :mode="mode" @change-mode="changeMode"></WhoAmI-Footer>
</template>

<script>

import WhoAmINavbar from "@/components/WhoAmI-Navbar.vue";
import WhoAmIFooter from "@/components/WhoAmI-Footer.vue";
import WhoAmIContent from "@/components/WhoAmI-Content.vue";
import WhoAmISettingsModal from "@/components/WhoAmI-Settings-Modal.vue";

export default {
  name: 'Who-Am-I',
  async created() {
    if(!localStorage.getItem('whoamiFrequency')){
      localStorage.setItem('whoamiFrequency', 5);
    }
    if(!localStorage.getItem('whoamiNumberOfNames')){
      localStorage.setItem('whoamiNumberOfNames', 5);
    }
  },
  components: {WhoAmISettingsModal, WhoAmIContent, WhoAmIFooter, WhoAmINavbar},
  methods: {
    changeMode(m) {
      this.mode = m;
      localStorage.setItem("whoamiMode", m);
      this.$refs.content.clearPredictedNames();
    },
    updateCamera(webcam) {
      console.log('Calling updateCamera');
      console.log(webcam);
      this.webcam = webcam;
      this.$refs.content.webcam = this.webcam;
      localStorage.setItem('whoamiWebcam', webcam);
      // this.$refs.content.initMediaStream();
      // this.$refs.content.initFaceDetection();
    },
    async updateSettings(settings) {
      let refreshCamera = false;
      let refreshModel = false;
      console.log('Calling updateSettings');
      console.log(JSON.stringify(settings.modelInfo));
      if(!this.webcam || this.webcam !== settings.webcam){
        this.webcam = settings.webcam;
        localStorage.setItem('whoamiWebcam', settings.webcam);
        this.$refs.content.webcam = this.webcam;
        refreshCamera = true;
      }
      if(!this.tfModelInfo || (settings.modelInfo && this.tfModelInfo.uuid !== settings.modelInfo.uuid) || (!settings.modelInfo)){
        this.tfModelInfo = settings.modelInfo;
        if(settings.modelInfo){
          localStorage.setItem("whoamiTfModelInfo", JSON.stringify(settings.modelInfo));
        }else{
          localStorage.removeItem("whoamiTfModelInfo");
        }
        this.$refs.content.tfModelInfo = this.tfModelInfo;
        this.$refs.content.refreshModel = true;
        refreshModel = true;
      }
      if(!localStorage.getItem("whoamiFrequency") || localStorage.getItem("whoamiFrequency") !== settings.frequency) {
        localStorage.setItem('whoamiFrequency', settings.frequency);
        refreshModel = true;
      }
      if(!localStorage.getItem("whoamiNumberOfNames") || localStorage.getItem("whoamiNumberOfNames") !== settings.numberOfNames) {
        localStorage.setItem('whoamiNumberOfNames', settings.numberOfNames);
        this.numberOfNames = settings.numberOfNames;
        refreshModel = true;
      }
      //await this.$refs.content.initModel();
      if(refreshCamera) await this.$refs.content.initMediaStream();
      if(refreshModel) await this.$refs.content.initModelExecution();
    }
  },
  data() {
    return {
      mode: localStorage.getItem("whoamiMode") === null ? 'top' : localStorage.getItem("whoamiMode"),
      tfModelInfo: JSON.parse(localStorage.getItem("whoamiTfModelInfo")),
      numberOfNames: localStorage.getItem('whoamiNumberOfNames'),
      webcam: localStorage.getItem("whoamiWebcam"),
    }
  }
}
</script>

<style>

</style>
